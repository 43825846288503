/*
@font-face {
  font-family: "Flaticon";
  src: url("../src/assets/fonts/Flaticon.eot");
  src: url("../src/assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
    url("../src/assets/fonts/Flaticon.woff") format("woff"),
    url("../src/assets/fonts/Flaticon.ttf") format("truetype"),
    url("../src/assets/fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../src/assets/fonts/Flaticon.svg#Flaticon") format("svg");
  }
}
*/

@mixin makeIcon($arg, $val) {

  .ai-#{$arg}:before,
  .ion-ios-ai-#{$arg}:before,
  .ion-ios-ai-#{$arg}-outline:before,
  .ion-md-ai-#{$arg}:before,
  .ion-md-ai-#{$arg}-outline:before {
    content: $val;
    font-size: 26px;
  }
}

.fi:before {
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  //font-smoothing: antialiased;
  //background: red;
  //padding: 100px;
}

@include makeIcon(flaticon-agenda, '\f100');
@include makeIcon(flaticon-airplane, '\f101');
@include makeIcon(flaticon-alarm-clock, '\f102');
@include makeIcon(flaticon-attachment, '\f103');
@include makeIcon(flaticon-attachment-1, '\f104');
@include makeIcon(flaticon-attachment-2, '\f105');
@include makeIcon(flaticon-back, '\f106');
@include makeIcon(flaticon-back-1, '\f107');
@include makeIcon(flaticon-back-2, '\f108');
@include makeIcon(flaticon-back-3, '\f109');


@include makeIcon(flaticon-basketball, '\f10a');
@include makeIcon(flaticon-battery, '\f10b');
@include makeIcon(flaticon-battery-1, '\f10c');
@include makeIcon(flaticon-beer, '\f10d');
@include makeIcon(flaticon-bell, '\f10e');
@include makeIcon(flaticon-bookmark, '\f10f');
@include makeIcon(flaticon-briefcase, '\f110');
@include makeIcon(flaticon-cake, '\f111');
@include makeIcon(flaticon-calendar, '\f112');
@include makeIcon(flaticon-cancel, '\f113');
@include makeIcon(flaticon-cancel-1, '\f114');
@include makeIcon(flaticon-candle, '\f115');
@include makeIcon(flaticon-cd, '\f116');
@include makeIcon(flaticon-checked, '\f117');
@include makeIcon(flaticon-checked-1, '\f118');
@include makeIcon(flaticon-circular-chart, '\f119');
@include makeIcon(flaticon-clock, '\f11a');
@include makeIcon(flaticon-cloud, '\f11b');
@include makeIcon(flaticon-cloudy, '\f11c');
@include makeIcon(flaticon-cocktail, '\f11d');
@include makeIcon(flaticon-cocktail-1, '\f11e');
@include makeIcon(flaticon-coffee-cup, '\f11f');
@include makeIcon(flaticon-compass, '\f120');
@include makeIcon(flaticon-compass-1, '\f121');
@include makeIcon(flaticon-credit-card, '\f122');
@include makeIcon(flaticon-cupcake, '\f123');
@include makeIcon(flaticon-diamond, '\f124');
@include makeIcon(flaticon-document, '\f125');
@include makeIcon(flaticon-dollar, '\f126');
@include makeIcon(flaticon-down-arrow, '\f127');
@include makeIcon(flaticon-down-arrow-1, '\f128');
@include makeIcon(flaticon-download, '\f129');
@include makeIcon(flaticon-download-1, '\f12a');
@include makeIcon(flaticon-download-2, '\f12b');
@include makeIcon(flaticon-eject, '\f12c');
@include makeIcon(flaticon-envelope, '\f12d');
@include makeIcon(flaticon-euro, '\f12e');
@include makeIcon(flaticon-eye, '\f12f');
@include makeIcon(flaticon-fast-forward, '\f130');
@include makeIcon(flaticon-file, '\f131');
@include makeIcon(flaticon-file-1, '\f132');
@include makeIcon(flaticon-file-2, '\f133');
@include makeIcon(flaticon-file-3, '\f134');
@include makeIcon(flaticon-file-4, '\f135');
@include makeIcon(flaticon-file-5, '\f136');
@include makeIcon(flaticon-file-6, '\f137');
@include makeIcon(flaticon-flag, '\f138');
@include makeIcon(flaticon-folder, '\f139');
@include makeIcon(flaticon-folder-1, '\f13a');
@include makeIcon(flaticon-funnel, '\f13b');
@include makeIcon(flaticon-garage, '\f13c');
@include makeIcon(flaticon-garbage, '\f13d');
@include makeIcon(flaticon-gear, '\f13e');
@include makeIcon(flaticon-gift, '\f13f');
@include makeIcon(flaticon-group, '\f140');
@include makeIcon(flaticon-headphones, '\f141');
@include makeIcon(flaticon-headset, '\f142');
@include makeIcon(flaticon-heart, '\f143');
@include makeIcon(flaticon-helm, '\f144');
@include makeIcon(flaticon-home, '\f145');
@include makeIcon(flaticon-hourglass, '\f146');
@include makeIcon(flaticon-id-card, '\f147');
@include makeIcon(flaticon-information, '\f148');
@include makeIcon(flaticon-key, '\f149');
@include makeIcon(flaticon-king, '\f14a');
@include makeIcon(flaticon-label, '\f14b');
@include makeIcon(flaticon-label-1, '\f14c');
@include makeIcon(flaticon-light-bulb, '\f14d');
@include makeIcon(flaticon-list, '\f14e');
@include makeIcon(flaticon-list-1, '\f14f');
@include makeIcon(flaticon-lotus, '\f150');
@include makeIcon(flaticon-medal, '\f151');
@include makeIcon(flaticon-medal-1, '\f152');
@include makeIcon(flaticon-megaphone, '\f153');
@include makeIcon(flaticon-menu, '\F154');
@include makeIcon(flaticon-microphone, '\f155');
@include makeIcon(flaticon-minus, '\f156');
@include makeIcon(flaticon-minus-1, '\f157');
@include makeIcon(flaticon-money, '\f158');
@include makeIcon(flaticon-monitor, '\f159');
@include makeIcon(flaticon-moon, '\f15a');
@include makeIcon(flaticon-mouse, '\f15b');
@include makeIcon(flaticon-musical-note, '\f15c');
@include makeIcon(flaticon-mute, '\f15d');
@include makeIcon(flaticon-next, '\f15e');
@include makeIcon(flaticon-next-1, '\f15f');
@include makeIcon(flaticon-next-2, '\f160');
@include makeIcon(flaticon-next-3, '\f161');
@include makeIcon(flaticon-next-4, '\f162');
@include makeIcon(flaticon-next-5, '\f163');
@include makeIcon(flaticon-notepad, '\f164');
@include makeIcon(flaticon-options, '\f165');
@include makeIcon(flaticon-padlock, '\f166');
@include makeIcon(flaticon-padlock-1, '\f167');
@include makeIcon(flaticon-pause, '\f168');
@include makeIcon(flaticon-pencil, '\f169');
@include makeIcon(flaticon-photo-camera, '\f16a');
@include makeIcon(flaticon-picture, '\f16b');
@include makeIcon(flaticon-pie-chart, '\f16c');
@include makeIcon(flaticon-placeholder, '\f16d');
@include makeIcon(flaticon-play-button, '\f16e');
@include makeIcon(flaticon-plus, '\f16f');
@include makeIcon(flaticon-plus-1, '\f170');
@include makeIcon(flaticon-pound-sterling, '\f171');
@include makeIcon(flaticon-power-button, '\f172');
@include makeIcon(flaticon-previous, '\f173');
@include makeIcon(flaticon-prohibition, '\f174');
@include makeIcon(flaticon-push-pin, '\f175');
@include makeIcon(flaticon-question, '\f176');
@include makeIcon(flaticon-redo, '\f177');
@include makeIcon(flaticon-reload, '\f178');
@include makeIcon(flaticon-rewind, '\f179');
@include makeIcon(flaticon-rocket-ship, '\f17a');
@include makeIcon(flaticon-ruler, '\f17b');
@include makeIcon(flaticon-saturn, '\f17c');
@include makeIcon(flaticon-scissors, '\f17d');
@include makeIcon(flaticon-search, '\f17e');
@include makeIcon(flaticon-shirt, '\f17f');
@include makeIcon(flaticon-shopping-bag, '\f180');
@include makeIcon(flaticon-shopping-basket, '\f181');
@include makeIcon(flaticon-shopping-cart, '\f182');
@include makeIcon(flaticon-smartphone, '\f183');
@include makeIcon(flaticon-snowflake, '\f184');
@include makeIcon(flaticon-speaker, '\f185');
@include makeIcon(flaticon-speech-bubble, '\f186');
@include makeIcon(flaticon-speech-bubble-1, '\f187');
@include makeIcon(flaticon-speedometer, '\f188');
@include makeIcon(flaticon-sprout, '\f189');
@include makeIcon(flaticon-star, '\f18a');
@include makeIcon(flaticon-stats, '\f18b');
@include makeIcon(flaticon-stop, '\f18c');
@include makeIcon(flaticon-storm, '\f18d');
@include makeIcon(flaticon-storm-1, '\f18e');
@include makeIcon(flaticon-storm-2, '\f18f');
@include makeIcon(flaticon-sun, '\f190');
@include makeIcon(flaticon-tablet, '\f191');
@include makeIcon(flaticon-target, '\f192');
@include makeIcon(flaticon-telephone, '\f193');
@include makeIcon(flaticon-tie, '\f194');
@include makeIcon(flaticon-truck, '\f195');
@include makeIcon(flaticon-umbrella, '\f196');
@include makeIcon(flaticon-undo, '\f197');
@include makeIcon(flaticon-up-arrow, '\f198');
@include makeIcon(flaticon-up-arrow-1, '\f199');
@include makeIcon(flaticon-upload, '\f19a');
@include makeIcon(flaticon-upload-1, '\f19b');
@include makeIcon(flaticon-upload-2, '\f19c');
@include makeIcon(flaticon-user, '\f19d');
@include makeIcon(flaticon-user-1, '\f19e');
@include makeIcon(flaticon-user-2, '\f19f');
@include makeIcon(flaticon-wallet, '\f1a0');
@include makeIcon(flaticon-warning, '\f1a1');
@include makeIcon(flaticon-wifi, '\f1a2');
@include makeIcon(flaticon-wifi-1, '\f1a3');
@include makeIcon(flaticon-wine, '\f1a4');
@include makeIcon(flaticon-world-grid, '\f1a5');
@include makeIcon(flaticon-wrench, '\f1a6');
@include makeIcon(flaticon-yen, '\f1a7');
@include makeIcon(flaticon-zoom-in, '\f1a8');
@include makeIcon(flaticon-zoom-out, '\f1a9');

$font-Flaticon-agenda: "\f100";
$font-Flaticon-airplane: "\f101";
$font-Flaticon-alarm-clock: "\f102";
$font-Flaticon-attachment: "\f103";
$font-Flaticon-attachment-1: "\f104";
$font-Flaticon-attachment-2: "\f105";
$font-Flaticon-back: "\f106";
$font-Flaticon-back-1: "\f107";
$font-Flaticon-back-2: "\f108";
$font-Flaticon-back-3: "\f109";
$font-Flaticon-basketball: "\f10a";
$font-Flaticon-battery: "\f10b";
$font-Flaticon-battery-1: "\f10c";
$font-Flaticon-beer: "\f10d";
$font-Flaticon-bell: "\f10e";
$font-Flaticon-bookmark: "\f10f";
$font-Flaticon-briefcase: "\f110";
$font-Flaticon-cake: "\f111";
$font-Flaticon-calendar: "\f112";
$font-Flaticon-cancel: "\f113";
$font-Flaticon-cancel-1: "\f114";
$font-Flaticon-candle: "\f115";
$font-Flaticon-cd: "\f116";
$font-Flaticon-checked: "\f117";
$font-Flaticon-checked-1: "\f118";
$font-Flaticon-circular-chart: "\f119";
$font-Flaticon-clock: "\f11a";
$font-Flaticon-cloud: "\f11b";
$font-Flaticon-cloudy: "\f11c";
$font-Flaticon-cocktail: "\f11d";
$font-Flaticon-cocktail-1: "\f11e";
$font-Flaticon-coffee-cup: "\f11f";
$font-Flaticon-compass: "\f120";
$font-Flaticon-compass-1: "\f121";
$font-Flaticon-credit-card: "\f122";
$font-Flaticon-cupcake: "\f123";
$font-Flaticon-diamond: "\f124";
$font-Flaticon-document: "\f125";
$font-Flaticon-dollar: "\f126";
$font-Flaticon-down-arrow: "\f127";
$font-Flaticon-down-arrow-1: "\f128";
$font-Flaticon-download: "\f129";
$font-Flaticon-download-1: "\f12a";
$font-Flaticon-download-2: "\f12b";
$font-Flaticon-eject: "\f12c";
$font-Flaticon-envelope: "\f12d";
$font-Flaticon-euro: "\f12e";
$font-Flaticon-eye: "\f12f";
$font-Flaticon-fast-forward: "\f130";
$font-Flaticon-file: "\f131";
$font-Flaticon-file-1: "\f132";
$font-Flaticon-file-2: "\f133";
$font-Flaticon-file-3: "\f134";
$font-Flaticon-file-4: "\f135";
$font-Flaticon-file-5: "\f136";
$font-Flaticon-file-6: "\f137";
$font-Flaticon-flag: "\f138";
$font-Flaticon-folder: "\f139";
$font-Flaticon-folder-1: "\f13a";
$font-Flaticon-funnel: "\f13b";
$font-Flaticon-garage: "\f13c";
$font-Flaticon-garbage: "\f13d";
$font-Flaticon-gear: "\f13e";
$font-Flaticon-gift: "\f13f";
$font-Flaticon-group: "\f140";
$font-Flaticon-headphones: "\f141";
$font-Flaticon-headset: "\f142";
$font-Flaticon-heart: "\f143";
$font-Flaticon-helm: "\f144";
$font-Flaticon-home: "\f145";
$font-Flaticon-hourglass: "\f146";
$font-Flaticon-id-card: "\f147";
$font-Flaticon-information: "\f148";
$font-Flaticon-key: "\f149";
$font-Flaticon-king: "\f14a";
$font-Flaticon-label: "\f14b";
$font-Flaticon-label-1: "\f14c";
$font-Flaticon-light-bulb: "\f14d";
$font-Flaticon-list: "\f14e";
$font-Flaticon-list-1: "\f14f";
$font-Flaticon-lotus: "\f150";
$font-Flaticon-medal: "\f151";
$font-Flaticon-medal-1: "\f152";
$font-Flaticon-megaphone: "\f153";
$font-Flaticon-menu: "\f154";
$font-Flaticon-microphone: "\f155";
$font-Flaticon-minus: "\f156";
$font-Flaticon-minus-1: "\f157";
$font-Flaticon-money: "\f158";
$font-Flaticon-monitor: "\f159";
$font-Flaticon-moon: "\f15a";
$font-Flaticon-mouse: "\f15b";
$font-Flaticon-musical-note: "\f15c";
$font-Flaticon-mute: "\f15d";
$font-Flaticon-next: "\f15e";
$font-Flaticon-next-1: "\f15f";
$font-Flaticon-next-2: "\f160";
$font-Flaticon-next-3: "\f161";
$font-Flaticon-next-4: "\f162";
$font-Flaticon-next-5: "\f163";
$font-Flaticon-notepad: "\f164";
$font-Flaticon-options: "\f165";
$font-Flaticon-padlock: "\f166";
$font-Flaticon-padlock-1: "\f167";
$font-Flaticon-pause: "\f168";
$font-Flaticon-pencil: "\f169";
$font-Flaticon-photo-camera: "\f16a";
$font-Flaticon-picture: "\f16b";
$font-Flaticon-pie-chart: "\f16c";
$font-Flaticon-placeholder: "\f16d";
$font-Flaticon-play-button: "\f16e";
$font-Flaticon-plus: "\f16f";
$font-Flaticon-plus-1: "\f170";
$font-Flaticon-pound-sterling: "\f171";
$font-Flaticon-power-button: "\f172";
$font-Flaticon-previous: "\f173";
$font-Flaticon-prohibition: "\f174";
$font-Flaticon-push-pin: "\f175";
$font-Flaticon-question: "\f176";
$font-Flaticon-redo: "\f177";
$font-Flaticon-reload: "\f178";
$font-Flaticon-rewind: "\f179";
$font-Flaticon-rocket-ship: "\f17a";
$font-Flaticon-ruler: "\f17b";
$font-Flaticon-saturn: "\f17c";
$font-Flaticon-scissors: "\f17d";
$font-Flaticon-search: "\f17e";
$font-Flaticon-shirt: "\f17f";
$font-Flaticon-shopping-bag: "\f180";
$font-Flaticon-shopping-basket: "\f181";
$font-Flaticon-shopping-cart: "\f182";
$font-Flaticon-smartphone: "\f183";
$font-Flaticon-snowflake: "\f184";
$font-Flaticon-speaker: "\f185";
$font-Flaticon-speech-bubble: "\f186";
$font-Flaticon-speech-bubble-1: "\f187";
$font-Flaticon-speedometer: "\f188";
$font-Flaticon-sprout: "\f189";
$font-Flaticon-star: "\f18a";
$font-Flaticon-stats: "\f18b";
$font-Flaticon-stop: "\f18c";
$font-Flaticon-storm: "\f18d";
$font-Flaticon-storm-1: "\f18e";
$font-Flaticon-storm-2: "\f18f";
$font-Flaticon-sun: "\f190";
$font-Flaticon-tablet: "\f191";
$font-Flaticon-target: "\f192";
$font-Flaticon-telephone: "\f193";
$font-Flaticon-tie: "\f194";
$font-Flaticon-truck: "\f195";
$font-Flaticon-umbrella: "\f196";
$font-Flaticon-undo: "\f197";
$font-Flaticon-up-arrow: "\f198";
$font-Flaticon-up-arrow-1: "\f199";
$font-Flaticon-upload: "\f19a";
$font-Flaticon-upload-1: "\f19b";
$font-Flaticon-upload-2: "\f19c";
$font-Flaticon-user: "\f19d";
$font-Flaticon-user-1: "\f19e";
$font-Flaticon-user-2: "\f19f";
$font-Flaticon-wallet: "\f1a0";
$font-Flaticon-warning: "\f1a1";
$font-Flaticon-wifi: "\f1a2";
$font-Flaticon-wifi-1: "\f1a3";
$font-Flaticon-wine: "\f1a4";
$font-Flaticon-world-grid: "\f1a5";
$font-Flaticon-wrench: "\f1a6";
$font-Flaticon-yen: "\f1a7";
$font-Flaticon-zoom-in: "\f1a8";
$font-Flaticon-zoom-out: "\f1a9";