/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
//@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "../src/theme/icons.scss";

// UNCOMMENT TO ENABLE VISUAL DEBUGGING
// @import "../src/theme/debug.scss";

@font-face {
    font-family: "Flaticon";
    src: url("../src/assets/fonts/Flaticon.eot");
    src: url("../src/assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
        url("../src/assets/fonts/Flaticon.woff") format("woff"),
        url("../src/assets/fonts/Flaticon.ttf") format("truetype"),
        url("../src/assets/fonts/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}

* {
    font-family: 'Roboto', sans-serif;
    --ripple-color: transparent;
}

[hidden] {
    display:block !important;
}
ion-toolbar {
    --background: var(--ion-color-primary);
    color: white;
    box-shadow: 1px 1px 12px 2px #00000010;
    overflow: auto !important;
    position: relative !important;
    padding-bottom: 24px;
}

app-contact-item {
    margin: 0 0px;
}

.shadow {
    -webkit-box-shadow: 0px 2px 10px 0px rgba(107,107,107,0.5);
    -moz-box-shadow: 0px 2px 10px 0px rgba(107, 107, 107, 0.5);
    box-shadow: 0px 2px 10px 0px rgba(107,107,107,0.5);
}

.search-margin {
   top: 0px;
   display:block;
   margin-top: 105px;
}

.ios-search-margin{
    margin-top: 145px !important;
    @if variable-exists($name: safe-area-inset-top){
        margin-top: 115px + var(--safe-area-inset-top) !important;
    }
}

.search-margin-small {
   top: 0px;
   display:block;
   margin-top: 115px;
}

.ios-search-margin-small{
    margin-top: 155px !important;
    @if variable-exists($name: safe-area-inset-top){
        margin-top: 125px + var(--safe-area-inset-top) !important;
    }
}

.twentypx-padding{
    display: block;
    margin-top: 30px !important;
    @if variable-exists($name: safe-area-inset-top){
        margin-top: 10px + var(--safe-area-inset-top) !important;
    }
}

.center-text {
    text-align: center;
    --text-align: center;
}

ion-content {
    --background: var(--ion-color-tertiary);
    background: var(--ion-color-tertiary);
}

ion-button {
    text-transform: capitalize;
}

body {
    --background: var(--ion-color-tertiary);
    background: var(--ion-color-tertiary);
}

html {
    --background: var(--ion-color-tertiary);
    background: var(--ion-color-tertiary);
}

.overflow {
    overflow: visible;
    white-space: nowrap;
}

.tabs {
    z-index: 0 !important;
    --z-index: 0 !important;
}

.tabs-bar {
    padding-top: 18px;
}

.row-icon-container {
    overflow: visible;
    white-space: nowrap;
}
 
.row-icon {
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
}

.task-button {
    text-transform: none;
}

.taskdoneicon:before
{
    font-size: 15px;
    color: var(--ion-color-primary);
    position: relative;
    margin-right: 8px;
}

.item-avatar {
    position: relative;
    text-align: center;
    width: 12vw;
    height: 12vw;
    max-height: 40px;
    max-width: 40px;
}

.tab-text
{
    font-weight: 600;
    font-size: 14px;
}

.tab-icon:before{
    font-size: 17px;
}

.tab-icon{
    display: inline-block;
    position: absolute;
    left: -24px;
}

.tab-item {
    --padding: 0px;
    overflow: visible !important;
    white-space: nowrap !important;
    --background: none;
    --inner-padding-start: 0px;
    --padding-start: 0px;
}

.tab-text {
    position: absolute;
    transform: translateX(-65%);
    left: 50%;
}

.No-data-found{
    width: 100%;
    text-align: center;
    margin-top: 10px;
    position: absolute;
}

.edit-fab-container {
    position: absolute;
    display: block;
    right: 0px;
    top: var(--ion-safe-area-top);
}

.fab-list{
    z-index: 10000;
    --padding-top: var(--ion-safe-area-top);
}

.one-line-text-area{
    height: 38px !important;
    min-height: fit-content !important;
    overflow: hidden;
}

.item-textarea{
    overflow: unset !important;
}

.menu-button{
    width: 100%;
}

.pretty-date-text{
    position: absolute; 
    margin-left: 40px;
}

.transparant-text{
    color: rgba(0, 0, 0, 0);
}

.filter-button {
    position: absolute;
    margin-top: -22px;
    transform: translate(-50%,-50%);
    left: 50%;
    width: 500px;
    max-width: 82%;
    z-index: 100001;
}

.filter-icon{
    position: absolute;
    right: 13px;
}

.item-has-focus{
    --highlight-background: none;
}

.subheader{
    background-color: var(--ion-color-tertiary);
    display: block;
    text-align: center;
}

ion-footer{
    bottom: 0px;
    position: absolute;
}

.page-headings{
    text-align: left;
    font-size: 25px;
    font-weight: bold;
    padding-top: 2rem;
    padding-left: 1.5rem;
    padding-bottom: 1.3rem;
    color:var(--ion-text);
}

//header icons
.add-plus{
    font-size: 1.5rem;
    padding-right: 0.7rem;
}

.left-menu-icon{
    padding-left: 0.7rem;
}

.fa-chevron-left{
    font-size: 1.5rem;
    padding-left: 0.7rem;
}

.fa-chevron-right{
    font-size: 1.5rem;
    padding-right: 0.7rem;
}

//header drop down icons
.cog-menu{
    font-size: 1.4rem;
}

.cog-menu-options{
    font-size: 1.2rem;
}

.details-icon{
    font-size: 1rem;
}

.details-icon-big{
    font-size: 1.3rem;
}

.ai-flaticon-plus-1, .add-plus, .done-button, ion-button.in-toolbar{
    border-width: 10px !important;
    border-right-width: 0px !important;
    border-style: solid;
    border-color: transparent;
}

.button-action-card {
    font-size: 13px !important;
    margin-top: 8px;
}

.button-action-card ion-icon{
    margin-top: -1px !important;
}

ion-button.in-toolbar{
    min-height: 40px;
}

.menu-content-open{
    pointer-events: all;
}

.page-element-center{
    margin-left: 1.3rem;
    margin-right: 1.3rem;
}

.page-element-center-card{
    margin-left: calc(1.3rem - 10px);
    margin-right: calc(1.3rem - 10px);
}

.page-element-spacing-top{
    margin-top: 10px;
}

.page-element-spacing-bottom{
    margin-bottom: 1.2rem;
}

.text-input{
    min-width: 220px;
}

.disable-background{
    position: fixed;
    top: -20%;
    left: 0px;
    height: 125%;
    width: 100%;
    z-index: 12;
    background-color: rgba(50, 50, 50, 0.1);
}

.pop-up{
    position: fixed;
    top: CALC(50% - 35px);
    left: CALC(50% - 35px);
    height: 70px;
    width: 70px;
    background-color: rgb(255, 255, 255);
    border-radius: 25%;
}

app-note-item{
    width: 100%;
}

.loading-spinner{
    display:flex;
    justify-content:center;
    align-items:center;
}

.non-standard-loading-spinner{
    display:unset;
    justify-content:unset;
    align-items:unset;
    position: absolute;
    left: calc(50% - 14px);
    top: calc(50% - 14px);
}

.white{
    color: white;
}

.blacktext{
    color: rgba(0, 0, 0, 1)
}

.save-icon{
    font-size: 22px;
    padding-right: 13px;
}

.add-item{
    font-size: 1.7rem;
}

.add-item-contact{
    font-size: 1.5rem;
    color: var(--ion-text);
    margin-top: 6px !important;
}

.fa-save{
    font-size: 1.3rem;
    padding-right: 0.7rem;
}

.modal-wrapper{
    --width: 100%;
    --height: 100%;
}

.subButton {
    width: 45%;
}

.action-description{
    display: inline-block;
    margin-top: 10px;
    overflow-wrap: anywhere;
}

#tabs-container{
    padding-left: 10px;
    padding-right: 10px;
}

.one-line-text-area div{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    --padding-top: 0px !important;
    --padding-bottom: 0px !important;
    width: 100%;
    height: 20px;
    margin-top: 8px !important;
    margin-bottom: 10px;
}

.trash{
    font-size: 20px;
}

.native-input{
    transform: unset !important;
}

.native-textarea{
    transform: unset !important;
}

.cloned-input{
    visibility: hidden;
}

.wide-button{
    // width: 90% !important;
    height: 40px !important;
}

.full-width{
    width: 100%;
    height: 40px !important;
}

.push-bottom{
    margin-bottom: 15px;
}

.loader.circle{
    background: rgb(247, 150, 11) no-repeat !important;
}

.showpassword{
    position: absolute;
    margin-top: -45px;
    width: 26px;
    height: 26px;
    background-size: 26px 26px;
    z-index: 5;
    right: calc(5% + 25px);
    background-image: url("assets/icon/eye.svg");
}

.showingpassword{
    position: absolute;
    margin-top: -45px;
    width: 26px;
    height: 26px;
    background-size: 26px 26px;
    z-index: 5;
    right: calc(5% + 25px);
    background-image: url("assets/icon/eye-off.svg");
}

.fi:before{
    font-size: 22px;
}

.show-more-less{
    height: unset !important;
}

.circle-override .loader.circle{
    width: 100% !important;
    height: 100% !important;
    margin: 0px !important;
}

.actionpath-circle-override .loader.circle{
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.fiftywidth{
    width: 50%;
}

.cancel-sub-load{
    height: 100%;
    width: 100%;
    padding-top: 62px;
}

.no-hover{
    --background-hover: var(--background);
}

.no-hover:hover{
    background-color: var(--background);
    --background-color: var(--background);
}

ion-header{
    position: fixed;
    top: 0px;
}

.fixDesktopDetailHeader{
    width: calc(100% - 235px);
}

.select-page-buttonholder{
    padding-top: 10px;
    padding-bottom: 10px;
}

.desktop-title-align{
    left: calc(50% - 117px) !important;
}

.desktop-alphabet{
    right: 24px !important;
}

.avatar-avatar{
    background-color: rgba(0, 0, 0, 0) !important;
}

.item-avatar{
    display: block !important;
}

ion-toast.toastCustomClass{
    text-align: center;
    --box-shadow: 0 1px 3px -1px rgba(0, 0, 0, .2), 0 3px 3px 0 rgba(0, 0, 0, .06), 0 3px 10px 0 rgba(0, 0, 0, .06);
    --webkit-box-shadow: none;
    --background: var(--ion-color-secondary);
    --color: var(--ion-color-primary);
    width: 94%;
    margin-left: 3%;
}

.greenToast{
    --background: rgb(15, 120, 15) !important;
    color: white;
}

.redToast{
    --background: rgb(120, 15, 15) !important;
    color: white;
}

ion-toast.toptoast{
    margin-top: 10px;
}

ion-toast.bottomtoast{
    margin-top: -64px;
}

.fullscreen-modal{
    --height: 100%;
    --width: 100%;
}

.fullscreen-modal .ion-page ion-header{
    width: 100%;
}

.fullscreen-modal .ion-page ion-content{
    --padding-top: 40px;
}

.fullscreen-modal .ion-page ion-content .bonus-modal-padding{
    padding-top: 40px;
}

.fullscreen-modal .ion-page ion-content .bonus-mini-modal-padding{
    padding-top: 5px;
}

.optionButton{
    min-width: 40%;
}

.new-style-icon{
    font-size: 32px;
}

.text-color{
    color: var(--ion-text);
}

.text-color-dark{
    color: var(--ion-text-dark);
}

.top-tab-icon{
    display: block;
    float: left;
    margin-right: 4px;
    font-size: 18px;
}

.add-action-icon{
    display: block;
    float: left;
    margin-right: 8px;
    font-size: 24px;
}

.add-to-calendar-div *{
    visibility: visible;
}

.fav-star{
   font-size: 24px;
   padding-top: 0.5rem;
   .far{
      color:var(--ion-text);
   }
}

.icon-small{
    font-size: 32px;
    .note-contact-icon {
        padding-top: 16px;
        padding-left: 16px;
        color: black;
    }
    
}

.action-title{
  font-size: 18px;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

qrcode{
    display: inline-block;
    text-align: center;
    width: 100%;
    position: fixed;
}

.group-remove-contact-container{
    position: absolute;
    right: 5px;
    height: 40px;
    width: 40px;
    margin-top: 10px;
    padding-top: 10px;
    z-index: 5;
    text-align: center;
}

.group-remove-contact-icon{
    height: 20px;
    width: 20px;
}

.small-thickness-push{
    padding-top: 5px;
}

.ios-thickness-push{
    padding-top: 45px;
}

.faded{
    color: rgba(255, 255, 255, 0.4);
    pointer-events: none;
}

.item-avatar, .avatar{
    border-radius: 50%;
    background-color: var(--ion-color-avatar);
    text-align: center;
}

.no-avatar ion-icon{
    height: 60%;
    width: 60%;
    margin-top: 20%;
    color: white;
}

image-cropper{
    margin-top: 80px;
}

.real-searchbar{
    width: 100%;
    padding: 0px;
}

.real-searchbar div .searchbar-input{
    padding-inline-start: 50px !important;
    padding-inline-end: 50px !important;
}

.skeleton-circle div{
    width: 100% !important;
    height: 100% !important;
    background: var(--ion-color-avatar) !important;
    margin: 0px !important;
}

.action-card{
    min-height: 110px;
    margin-left: 16px;
    margin-right: 16px;
}

.action-page-item div div .action-card{
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.corrected-card{
    margin-left: calc(1.3rem - 10px);
    margin-right: calc(1.3rem - 10px)
}

.goals-for-today{
    margin-top: calc(1.3rem + 5px);
    margin-bottom: 1.3rem;
}

.search-AP ion-card{
    margin-left: 16px !important;
    margin-right: 16px !important;
}

app-action-sequence div div div app-action-item div div ion-card{
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.no-padding-list{
    padding-top: 0px;
    padding-bottom: 0px;
}

.z-index-30012{
    z-index: 30012;
}

.selected-action div div ion-card{
    background-color: rgba(0, 0, 0, 0.1);
}

.selected-action div div ion-card ion-card-content .title-avatar-wrap div .square-avatar{
    background-color: var(--ion-color-tertiary-secondary-darker) !important;
}

.square-avatar{
    border-radius: 50%;
}

.cropper-margin{
    top: 0px;
    display:block;
}

.ios-cropper-margin{
    margin-top: 30px;
}

.hideDiv{
    visibility: hidden !important;
    height: 0px !important;
}

.selected-item{
    --background: rgba(0, 0, 0, 0.1)
}

.transparant-background ion-item{
    --background: transparent;
}

.v-done{
    font-size: 12px;
    position: absolute;
    margin-top: 24px;
}

.v-icon{
    font-size: 24px;
    margin-top: -8px;
}

.finish-select-button{
    position: absolute;
    right: 0px;
}

ion-toolbar ion-buttons ion-button{
    height: 54px !important;
}

.add-contact-group-contact ion-item ion-grid ion-row ion-col ion-avatar{
    margin-top: 7px;
}


.contact-group-detail-contact ion-item ion-grid ion-row ion-col ion-avatar{
    margin-top: 5px;
}

.add-contact-group-contact ion-item ion-grid ion-row{
    padding-top: 1.5px;
    padding-bottom: 1.5px;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
}

.add-contact-group-contact ion-item ion-grid ion-row ion-text{
    height: 20px;
}

.contact-group-detail-contact ion-item ion-grid ion-row{
    padding-top: 1.5px;
    padding-bottom: 1.5px;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
}

.textarea-wrapper-inner div{
    position: absolute;
}
ion-card-content ion-item{
    --padding-start: 16px;
}

// .menu-items ion-icon, .sign-out, .sign-in, .in-list,
// .app-contact-item ion-item, .app-contact-group-item ion-item{
//     padding-inline-start: 16px;
// }

.priority-indicator{
    position: absolute;
    right: 0px;
    font-size: 36px;
    margin-top: -3px;
    text-align: center;
    width: 30px;
}

.failed-goal-action{
    background-color: rgba(255, 0, 0, 0.1);
}

.succesful-goal-action{
    background-color: rgba(0, 255, 0, 0.1);
}

.selectable-text{
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.hiddenContent{
    visibility: hidden;
}

.container{
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 11;
}

.container .edit-fab-container{
    top: -80px;
}

.action-user-holder{
    text-transform: none;
}

//All alert css data is beneath this

.alert-wrapper{
    max-width: 300px !important;
}

.alert-button-inner{
    justify-content: center !important;
    text-align: center;
}

.alert-title{
    text-align: center;
}

.alert-button{
    width: 100%;
}

//css for all alert pop-ups 
.custom-alert{  
    .alert-head{
        background: var(--ion-color-primary);
        text-align: left;
    }

    .alert-title{
        color: white;
    }
} 

.custom-alert .optionButton {
    .alert-button-inner{
        text-align: left;
        color: var(--ion-color-primary);
        display: flex;
        justify-content: flex-start;
    }
}

//referrs to any task being completed (action, notes, contacts, etc.)
.custom-alert{
    .alert-head{
        background: var(--ion-color-primary);
    }

    .alert-title{
        color: var(--ion-color-tertiary);
      }

    .alert-button-group{
        justify-content: center !important;
    }
}


.custom-alert-top-padding{
    .alert-message{
        padding-top: 20px !important;
    }
}

.wider-alert .alert-wrapper{
    min-width: 350px !important;
}

.alert-button-group{
    justify-content: center !important;
}

.subscription-alert {
    .alert-head{
        background: var(--ion-color-primary);
    }

    .alert-wrapper{
        max-width: 86%;
    }

    .alert-title{
        color: var(--ion-color-tertiary);
      }

    .alert-message{
        padding-top: 20px !important;
        padding-left: 10px;
        padding-right: 10px;
        height: 90%;
        max-height: unset;
    }

    .alert-button-group{
        justify-content: center !important;
    }
}

//referrs to any task being completed (action, notes, contacts, etc.)
.yes-no-alert{
    .alert-wrapper{
        width: 300px;
    }

    .alert-head{
        background: var(--ion-color-primary);
    }

    .alert-title{
        color: var(--ion-color-tertiary);
      }

    .alert-message{
        padding-top: 0px;
    }

    .alert-button-group{
        justify-content: center !important;
    }

    .alert-button-group .alert-button{
        width: 43%;
        background-color: rgba(0, 0, 0, 0.05) !important;
        border-color: rgb(155, 155, 155) !important;
        border-style: solid !important;
        border-width: 1px !important;
        border-radius: 15px !important;
    }
}

.full-screen-alert .alert-wrapper {
    height: 100%;
    width: 90%;
    max-width: unset !important;
}

.full-screen-alert .alert-wrapper .alert-message {
    max-height: unset !important;
    height: 100%;
}

.alert-message{
    padding-inline-start: 5% !important;
    padding-inline-end: 5% !important;
    justify-content: center !important;
    text-align: center;
}

.alert-wrapper{
    max-width: unset !important;
}

@media screen and (max-width: 500px) {
    .alert-wrapper{
        width: calc(90% - 2.6rem) !important;
    }
}

@media screen and (min-width: 500px) {
    .alert-wrapper{
        min-width: 300px !important;
    }
}

.delete-yes{
    background-color: rgba(0, 0, 0, 0.05) !important;
    width: 52%;
    border-color: var(--ion-color-primary) !important;
    border-style: solid !important;
    border-width: 1px !important;
    border-radius: 15px !important;
}

.delete-no{
    background-color: rgba(0, 0, 0, 0.05) !important;
    width: 43%;
    border-color: rgb(155, 155, 155) !important;
    border-style: solid !important;
    border-width: 1px !important;
    border-radius: 15px !important;
}

.first-yes-no{
    margin-inline-end: 5% !important;
}

.second-yes-no{
    margin-inline-end: 0px !important;
}

.note-contact-input .native-input{
    cursor: pointer !important;
}

.checkbox-icon{
    background: white !important;
}

.small-icon{
    font-size: 22px !important;
    margin-right: 6px;
}

.bottom-button{
    position: relative;
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-secondary);
    border-color: black;
    border-style: solid;
    border-width: 1px;
    border-radius: 10px;
    margin-bottom: 25px;
    padding: 10px;
    padding-top: 12px;
    text-align: center;
    font-weight: 550;
    font-size: 16px;
    margin: 16px;
}

.faded-bottom-button{
    opacity: 0.4;
    pointer-events: none;
}

.note-card{
    margin-left: 16px;
    margin-right: 16px;
}

.action-holder{
    display: inline-block;
    vertical-align: top;
    width: 100%;
}

.left-large-button{
    width: 90%;
    display: inline-block;
}

.right-small-button{
    width: 10%;
    height: 100%;
    font-size: 24px;
    display: inline-block;
    border-style: solid;
    border-width: 0px;
    border-left-width: 1px;
}

.right-small-button div{
    padding-top: 6px;
}

.gray-border{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

body {
  margin: 0;
  overflow: hidden;
}

.wrapper {
  position: relative;
  min-height: 100vh;
}

[class|="confetti"] {
  position: absolute;
}

.confetti-container{
    z-index: 30;
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    pointer-events: none 
}

$confettiColors: (#d13447, #ffbf00, #263672);

@for $i from 0 through 100 {
  $w: random(8);
  $l: random(100);
  .confetti-#{$i} {
    width: #{$w}px;
    height: #{$w*0.4}px;
    background-color: nth($confettiColors, random(3));
    top: -10%;
    left: unquote($l+"%");
    opacity: random() + 0.5;
    transform: rotate(#{random()*360}deg);
    animation: drop-#{$i} unquote(4+random()+"s") unquote(random()+"s") infinite;
  }

  @keyframes drop-#{$i} {
    100% {
      top: 110%;
      left: unquote($l+random(15)+"%");
    }
  }
}

.cancelButton{
    padding-top: 6px;
    width: 99%;
    .alert-button-inner{
        justify-content: center !important;
    }
}

.wideButton{
    padding-top: 6px;
    width: 99%;
    .alert-button-inner{
        justify-content: center !important;
    }
}